// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-government-js": () => import("./../../../src/pages/government.js" /* webpackChunkName: "component---src-pages-government-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiors-js": () => import("./../../../src/pages/interiors.js" /* webpackChunkName: "component---src-pages-interiors-js" */),
  "component---src-pages-residential-js": () => import("./../../../src/pages/residential.js" /* webpackChunkName: "component---src-pages-residential-js" */),
  "component---src-pages-retrofit-js": () => import("./../../../src/pages/retrofit.js" /* webpackChunkName: "component---src-pages-retrofit-js" */)
}

